// import Errors from "./FormErrors";
import axios from '@/libs/axios'
import useFlash from '@/composables/useFlash'
import { removeEmptyObjects, removeUndefinedValues } from '@/helpers/objects'

const { flashChangesSaved, flashError } = useFlash()

export default class Form {
  /**
   * Create a new Form instance.
   *
   * @param {object} data
   * @param {boolean} formData
   */
  constructor(data, formData = false) {
    this.defaultErrorMessage = 'Sorry, something has gone wrong. Please try again.'
    this.isLoading = false
    this.successful = false
    this.formData = formData
    this.flashMessages = true
    this.validationObserver = null
    this.keepDataWhenResetting = []
    this.error = {
      status: false,
      message: this.defaultErrorMessage,
      errors: false,
    }

    // Clone data
    this.originalData = data
    Object.assign(this, data)
    // this.errors = new Errors();
  }

  /**
   * Fetch all relevant data for the form.
   * @param {string} requestType // Support retrieving the form data without filtering it for an ajax request.
   */
  data(requestType = null) {
    let data = {}

    // Check if originalData is an array.
    if (Array.isArray(this.originalData)) {
      data = []

      this.originalData.forEach((key, index) => {
        if (requestType && typeof this[index] === 'object') {
          this.filter(this[index], requestType)
        }
        data.push(this[index])
      })
    } else {
      // Copy only original properties and their values otherwise unnecessary data will be sent up in request.
      Object.keys(this.originalData).forEach(key => { data[key] = this[key] })

      if (requestType) {
        this.filter(data, requestType)
      }
    }

    // Check if data should be FormData.
    if (requestType && this.formData) {
      const formData = new FormData()

      this.buildFormData(formData, data)

      if (this.appendPatch) {
        formData.append('_method', 'PATCH')
      }

      data = formData
    }

    return data
  }

  buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
      })
    } else {
      const value = data == null ? '' : data

      formData.append(parentKey, value)
    }
  }

  filter(data, requestType) {
    // Remove any empty nested objects.
    if (requestType === 'post' && !this.appendPatch) {
      removeEmptyObjects(data)
    }

    removeUndefinedValues(data)
  }

  /**
   * Reset the form fields.
   */
  reset(resetStatus = false) {
    for (const field in this.originalData) {
      if (!this.keepDataWhenResetting.includes(field)) {
        this[field] = this.originalData[field]
      }
    }

    if (resetStatus) {
      this.resetStatus()
    }

    if (this.validationObserver) {
      this.validationObserver.reset()
    }

    // this.errors.clear();
  }

  resetStatus() {
    this.isLoading = false
    this.successful = false
    this.appendPatch = false
    this.error = {
      status: false,
      message: this.defaultErrorMessage,
    }
  }

  /**
   * Send a POST request to the given URL.
   * .
   * @param {string} url
   * @param {boolean} reset
   * @param {boolean} appendPatch // Files cannot be uploaded with any other method besides POST,
   *                              if you append _method patch to the FormData object it will send the request as a patch instead.
   */
  post(url, reset = false, appendPatch = false) {
    if (appendPatch) {
      this.formData = true
      this.appendPatch = true
    }

    return this.submit('post', url, reset)
  }

  /**
   * Send a PUT request to the given URL.
   * .
   * @param {string} url
   * @param {boolean} reset
   */
  put(url, reset = false) {
    return this.submit('put', url, reset)
  }

  /**
   * Send a PATCH request to the given URL.
   * .
   * @param {string} url
   * @param {boolean} reset
   */
  patch(url, reset = false) {
    return this.submit('patch', url, reset)
  }

  /**
   * Send a DELETE request to the given URL.
   * .
   * @param {string} url
   * @param {boolean} reset
   */
  delete(url, reset = false) {
    return this.submit('delete', url, reset)
  }

  /**
   * Submit the form.
   *
   * @param {string} requestType
   * @param {string} url
   * @param {boolean} reset
   */
  submit(requestType, url, reset) {
    this.isLoading = true // Show loading status

    return new Promise((resolve, reject) => {
      axios[requestType](url, this.data(requestType))
        .then(response => {
          this.onSuccess(response.data, reset)

          resolve(response.data)
        })
        .catch(error => {
          this.onFail(error.response, reset)

          reject(error.response)
        })
        .finally(() => {
          this.isLoading = false // Hide loading status
        })
    })
  }

  /**
   * Handle a successful form submission.
   *
   * @param {object} data
   * @param {boolean} reset
   */
  onSuccess(data, reset) {
    this.successful = true

    if (reset) {
      this.reset()
    }

    if (this.flashMessages) {
      flashChangesSaved()
    }
  }

  /**
   * Handle a failed form submission.
   *
   * @param {object} error
   * @param {boolean} reset
   */
  onFail(error, reset) {
    this.error.status = true // Set error
    this.error.message = error.data.message
    this.error.errors = error.data.errors || []

    if (reset) {
      this.reset()
    }

    // Check if there was a server error
    if (error.status === 500) {
      this.error.message = 'Sorry, something seems wrong on our end. Please contact our support.'
      // throw new Error('Something went wrong on the server.');
    }

    if (this.flashMessages) {
      flashError(this.error.message)
    }

    if (error.status === 422 && this.validationObserver) {
      this.validationObserver.setErrors(error.data.errors)
    }

    // this.errors.record(errors);
  }

  /**
   * Remove the key from the form.
   *
   * @param {string} key
   */
  remove(key) {
    delete this.originalData[key]
  }

  // // If object have to convert to array
  // if (typeof this[property] === 'object' && !(this[property] instanceof File) && this[property] !== null) {
  //   for (const prop in this[property]) {
  //     if (this[property][prop] === null) {
  //       this[property][prop] = ''
  //     }
  //     data.append(`${property}[${prop}]`, this[property][prop])
  //   }
  // } else if (this[property] !== undefined) {
  //   if (this[property] === null) {
  //     this[property] = ''
  //   }
  //   data.append(property, this[property])
  // }
}
